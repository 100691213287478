@import url('https://fonts.googleapis.com/css2?family=Quicksand:wght@300&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Caveat&display=swap');

*,
*::before,
*::after {
	box-sizing: border-box;
	margin: 0;
};

:root {
    line-height: 1.4;
	font-family: 'Quicksand', sans-serif;
    /* background-color: #272B30; */

    /* LANDING */
    --backLanding: #d8d8d80f;
    --backBtn: linear-gradient(35deg,#E533B7 0,#da13f5 100%);

    /* VARIABLES */
    --background1: #ffff;
    --background2:   #22242b;
    --background3:   #292d38;
    --background4: #32383e;
    --background5: #22242b93;

    --color1: #1a1a1a;
    --color2: #dadada;
    --color3: #fff;
    --color4: #da1fe9;
    --color5: linear-gradient(0deg,#1f1f2f,#424565);
    --color6:#45486b;
    --color7:#4d4d74;
    --color8:#636396;

    --text1:#252527;
	--text2:#808080;
	--text3:#afafbf;
    --text4:#d8d8d8;
    --text5:#ffffff;

    --err:#e91e63;
    --success:#5fd075;
	--warning: #f89406;
    --info: #06ccf8;

    --fontS: 12px;
    --fontM: 14px;
    --fontL: 18px;
    --fontXL: 20px;
    --fontXXL: 25px;
    --fontFam: 'Quicksand', sans-serif;

    --border:#b3b3b3;

	--blur: blur(10px);
	--hover:  #79797924;
	--shadow: 2px 4px 15px 0 rgba(0,0,0,.3);
    --radius: 5px;
    
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	
	--transition: 0.25s;
	--transition-quick: 0.1s;

	color: var(--text1);
	background: var(--background);
};

body{
    background-color:var(--background2) ;
 };

h1,
h2,
h3,
h4,
h5,
p,
a,
label,
button,
textarea,
input {
	font-weight: 500;
    letter-spacing: 1px;
    color: var(--text5);
    font-family: 'Quicksand', sans-serif;
};

label,
button {
	cursor: pointer;
	user-select: none;
};

p {
	color: var(--text2);
    font-size: 16px;
    line-height: 1.6em;
};

h1 {
	font-size: 2.5rem;
};

h2 {
	font-size: 1.85rem;
};

h3 {
	font-size: 1.5rem;
};
h4 {
	font-size: 1.3rem;
};

h5,
small {
	font-size: 0.85rem;
};

h6 {
	font-size: 0.7rem;
};

/* SCROLL BAR */
::-webkit-scrollbar {
    /* background-color:transparent; */
    /* overflow: visible; */
    width: 5px;
    // visibility: hidden;
    // background-color: red;
};

::-webkit-scrollbar-thumb {
    /* overflow: visible; */
    background: #636363b1;
    border: none;
    border-radius: 10px;
        &:hover{
            background: #636363;
            width: 15px;
                &::-webkit-scrollbar{
                    width: 15px;
                }
        }
};

 ::-webkit-scrollbar:horizontal {
    height: 5px;
};

 ::-webkit-scrollbar-button {
	background: transparent;
    width: 5px;
    height: 5px;
    // background-color: turquoise;
};

 ::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 10px;
    /* background-color: turquoise; */
    // background-color: var(--background2);
};

::-webkit-scrollbar-corner{
	background: transparent;
};
