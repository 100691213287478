.aboutUs {
    position: relative;
    width: 100%;
    max-width: 1000px;
    background: var(--background3);
    margin: auto;
    border-radius: var(--radius);
    /* height: 100vh; */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
    overflow: hidden;
    color:var(--text4)
}


/* Title */

.aboutUs-title-container {
    position: relative;
    width: 100%;
    display: flex;
}

.aboutUs-back {
    clip-path: polygon(7% 36%, 100% 0, 100% 77%, 0% 100%);
    width: 100%;
    height: 500px;
    /* background-image: linear-gradient(to top, #d5d4d0 0%, #d5d4d0 1%, #eeeeec 31%, #efeeec 75%, #e9e9e7 100%); */
    /* background-image: linear-gradient(135deg, #fdfcfb 0%, #e2d1c3 100%); */
    background-image: linear-gradient(135deg, #484848 0%, #e2d1c3 100%);    
}

.aboutUs-text {
    position: absolute;
    font-size: 50px;
    width: 100%;
    height: 500px;
    padding: 80px 40px;
    /* margin: 80px 0 0; */
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background: transparent;
}


/* Format Icons */

.section-container {
    width: 100%;
    /* height: 400px; */
    text-align: center;
    padding: 70px 0 0;
}

.mission>img {
    height: 40px;
}

.mission {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    background-image: linear-gradient(to top, #007adf 0%, #00ecbc 100%);
}

.chip>img {
    height: 40px;
}

.chip {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    background-image: linear-gradient(to top, #b224ef 0%, #7579ff 100%);
}

.solution>img {
    height: 40px;
}

.solution {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    /* background-image: linear-gradient(to top, #0fd850 0%, #f9f047 100%); */
    background-image: linear-gradient(120deg, #84fab0 0%, #8fd3f4 100%);
}

.team>img {
    height: 40px;
}

.team {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    background-image: linear-gradient(120deg, #f6d365 0%, #fda085 100%);
}

.super>img {
    height: 40px;
}

.super {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    background-image: linear-gradient(to top, #ff0844 0%, #ffb199 100%);
}

.aboutUs-tech {
    font-size: 30px;
    width: 100%;
    height: 80px;
    margin: 0px 0 10px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.aboutUs-desc {
    font-size: 20px;
    text-align: center;
    padding: 0 200px;
}


/* Tech Logo */

.aboutUs-tech-div {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 10px;
}

.aboutUs-tech-logo {
    /* background: white; */
    height: 77px;
    width: 77px;
    border-radius: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 4px;
    /* box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12); */
}

.aboutUs-tech-logo:hover {
    transform: scale(0.9);
    cursor: pointer;
}

.aboutUs-tooltiptext {
    font-size: 18px;
    visibility: hidden;
    width: 125px;
    background-color: rgba(0, 0, 0, 0.8);
    color: #fff;
    letter-spacing: 2px;
    font-weight: bold;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    bottom: 110%;
    left: 35px;
    margin-left: -60px;
}

.aboutUs-tech-logo:hover .aboutUs-tooltiptext {
    visibility: visible;
}


/* Solution */

.aboutUs-solution {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.solution-container {
    height: 200px;
    width: 100%;
    margin: 20px 50px;
}

.solution-title {
    font-size: 30px;
    padding: 10px 0;
}


/* Tell Your Friends */

.tell-container {
    width: 80%;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
    margin: auto;
    margin-bottom: 100px;
    margin-bottom: 20px;
}

.tell {
    width: 80%;
    background-color: var(--background2);
    border-radius: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    box-shadow: 0px 19px 26px -13px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 19px 26px -13px rgba(0, 0, 0, 0.75);
}

.tell-icon>img {
    height: 80px;
    width: 80px;
    padding: 20px;
    border-radius: 100px;
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    background: linear-gradient(87deg,#de7c8a 0,#da13f5 100%);
}

.tell-icon {
    padding: 10px;
    width: 30%;
    margin: 10px;
    text-align: center;
}

.tell-txt {
    width: 70%;
    font-size: 20px;
    /* text-align: center; */
}


/* Come Inside */

.come-inside {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 20px 0 50px;
    align-items: center;
    justify-content: center;
}

.come-inside-txt {
    font-size: 50px;
    padding: 0 0 20px;
    /* font-weight: bold; */
}

.come-inside-r {
    display: flex;
    justify-content: center;
}

.come-inside-btn {
    width: 200px;
    height: 50px;
    border: none;
    color: white;
    letter-spacing: 2px;
    font-weight: bold;
    border-radius: var(--radius);
    box-shadow: 0 2px 2px 0 rgb(0 0 0 / 14%), 0 3px 1px -2px rgb(0 0 0 / 20%), 0 1px 5px 0 rgb(0 0 0 / 12%);
    background: linear-gradient(87deg,#de7c8a 0,#da13f5 100%);
}

.come-inside-btn:hover {
    opacity: .7;
}

.come-inside-btn:active {
    transform: translateY(3px);
}


/* Responsiveness */

@media screen and (min-width: 1200px) {
    .aboutUs {
        max-width: 1500px;
    }
}

@media screen and (max-width: 1200px) {
    .aboutUs-desc {
        padding: 0 50px;
    }
    /* Tell Btn */
    .tell-container {
        width: 90%;
        margin-top: 100px;
    }
    .tell {
        width: 90%;
    }
    .tell-txt {
        font-size: 20px;
    }
    /* About Solutions */
    .aboutUs-solution {
        flex-wrap: wrap;
    }
    .solution-container {
        height: auto;
        margin: 20px 50px 0;
    }
    /* Come inside Div */
    .come-inside {
        display: block;
        margin: 30px 0 50px;
    }
    .come-inside-txt {
        font-size: 30px;
        padding: 0 20px;
        text-align: center;
    }
    .come-inside-btn {
        margin-top: 10px;
    }
    .come-inside-r {
        margin: 20px;
    }
}

@media screen and (max-width: 500px) {
    .section-container {
        height: auto;
    }
    .aboutUs-desc {
        padding: 0 10px;
    }
    /* Title */
    .aboutUs-text {
        font-size: 40px;
        height: 400px;
    }
    .aboutUs-back {
        height: 400px;
    }
    .aboutUs-tech {
        font-size: 30px;
    }
    .aboutUs-tech-logo {
        width: 40px;
        height: 40px;
    }
    .aboutUs-tech-logo>img {
        height: 30px;
    }
    .aboutUs-desc {
        font-size: 18px;
    }
    .aboutUs-tooltiptext {
        left: 20px;
        font-size: 16px;
    }
    /* Tell Btn */
    .tell-container {
        margin: 20px auto 20px
    }
    .tell-txt {
        font-size: 16px;
        padding: 0 0 0 15px;
    }
    .tell-icon>img {
        height: 80px;
        width: 80px;
    }
}