/* CSS for ContactUs */

.contactUs {
    overflow: hidden;
    height: 500px;
    position: relative;
    width: 100%;
    max-width: 800px;
    background: var(--color5);
    margin: 25px;
    padding: 20px;
    border-radius: var(--radius);
    display: block;
    text-align: center;
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

/* .contactUs::before {
    content: '';
    position: absolute;
    top: 0;
    left: 14%;
    width: 1000px;
    height: 1000px;
    background-image: linear-gradient(135deg, #000000 0%, #fafeff 100%);
    border-radius: 50%;
    transform-origin: top;
    transform: translateY(32%) scale(2);
    opacity: 0.7;
} */

.contactUs>h1 {
    position: relative;
    top: 25%;
    font-size: 30px;
}

.contactUs>h2 {
    font-size: 35px;
}

.contactUs>h4 {
    position: relative;
    top: 27%;
    font-size: 20px;
}

.contactUs-a {
    color: var(--text5)!important;
    text-decoration: none;
}

.contactUs-a:hover{
    color: var(--color4)!important;
}

.help>img {
    height: 40px;
}

.help {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    height: 80px;
    width: 80px;
    border-radius: 50px;
    position: relative;
    top: 20%;
    background-image: linear-gradient(to top, #007adf 0%, #00ecbc 100%);
}


/* .terms {
    padding: 20px;
    margin: 20px;
    line-height: 30px;
} */

.terms {
    position: relative;
    /* width: 100%; */
    background: var(--background3);
    margin: auto;
    padding: 20px;
    border-radius: 10px;
    /* height: 100vh; */
    box-shadow: 0 2px 2px 0 rgba(0, 0, 0, .14), 0 3px 1px -2px rgba(0, 0, 0, .2), 0 1px 5px 0 rgba(0, 0, 0, .12);
}

.terms>p {
    font-size: 16px;
    color:var(--text4);
}

.terms>p>a{
    color:var(--text4);
    font-weight: bold;
    text-decoration: none;
}

.terms>p>a:hover{
    text-decoration: underline;  
}

.terms-title>h1 {
    font-weight: bold;
    text-align: center;
    color:var(--text4);
    margin-top: 15px;
    font-size: 30px;
}

.terms-title>h6 {
    text-align: center;
    color:var(--text4);
    font-size: 14px;
}

.terms-hr {
    border: none;
    border-bottom: 1px solid rgba(156, 156, 156, 0.295);
    width: 90%;
    margin: auto;
}

.terms-subtitle>h4 {
    font-weight: bold;
    font-size: 18px;
    color: var(--text5);
    width: 300px;
    height: 50px;
    padding: 5px 0;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: var(--radius);
    margin-top: 10px;
    background-color: var(--backLanding);
}

.terms-subtitle {
    display: flex;
    justify-content: center;
    margin: 10px;
}

.csyes-span {
    font-weight: bold;
    
}

.terms-span {
    position: relative;
    display: inline-block;
    transform: rotate(10deg);
    -ms-transform: rotate(10deg);
    padding: 2px;
    font-size: 18px;
}

.span-stripe {
    font-weight: bold;
}


/* Responsiveness */

@media screen and (max-width: 500px) {
    .contactUs {
        height: 400px;
    }
    .contactUs>h1 {
        font-size: 30px;
    }
    .contactUs>h2 {
        font-size: 30px;
    }
    .contactUs>h4 {
        font-size: 16px;
    }
    .help {
        height: 80px;
        width: 80px;
    }
    .help>img {
        height: 40px;
    }
}