.legal {
    /* height: calc(100vh - 233px); */
    width: 100%;
    max-width: 1500px;
}

.legal-nav {
    position: relative;
    /* height: 30px; */
}

.legal-ul {
    display: flex;
    list-style: none;
    justify-content: center;
    align-items: center;
    padding: 0;
    margin: 30px 0;
}

.legal-ul>li {
    padding: 0 30px;
    font-size: 16px;
    cursor: pointer;
    color:var(--text4);
}

.legal-ul>li:hover {
    /* border-bottom: 1px solid #888787; */
    transform: scale(1.2)
}

@media screen and (max-width: 500px) {
    .legal-ul {
        display: block;
        text-align: center;
    }
}