.main-logo-body {
    position: relative;
    height: 120px;
    width: 150px;
    min-width: 150px;
    left: calc(50% - 75px);
    top: calc(50% - 75px);
}

.main-logo-text {
    position: relative;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    list-style: none;  
    margin: 0;
    padding: 0;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.91);
    display: flex;
    font-size: 40px;
    color: var(--color3);
    max-width: 2em;
    // border-bottom: 0;
    // border: bottom width 123px;
}

/*Move Circle ************************************ */
.circleBk{
    position: absolute;
    width: 85px;
    height: 85px;
    border-radius: 85px;
    background-image: linear-gradient(to top,#191927 0%,#3c3c5a);
    // background-color: #fff;
    top:50%;
    transform: translate(33px,-50%);
    animation: moveCircle 1s;
    animation-timing-function: 0.1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
}

@keyframes moveCircle {
    0% {
        opacity: 1;
        // transform: translateY(-50%), translateX(0);
        transform: translate(33px, -50%);
    }
    20% {
        opacity: 1;
        // transform: translateY(-50%), translateX(0);
        transform: translate(33px, -50%);
    }
    50% {
        opacity: 1;
        // transform: translateY(-50%), translateX(0);
        transform: translate(33px, -50%);
    }
    80% {
        opacity: 1;
        // transform: translateY(-50%), translateX(20px);
        transform: translate(0, -50%);
    }
    100% {
        opacity: 1;
        // transform: translateY(-50%), translateX(20px);
        transform: translate(0, -50%);
    }
}

/*Move S right ************************************ */
.main-logo-text li.spaced {
    left: 50px;
    position: absolute;
    color:var(--color3);
    /* animation: fadeUp 1s timing-function delay iteration-count direction fill-mode; */
    animation: fadeRight 1s;
    animation-timing-function: 0.1s;
    animation-delay: 1s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    -webkit-animation: fadeRight 1s;
    -webkit-animation-timing-function: 0.1s;
    -webkit-animation-delay: 1s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
}

@keyframes fadeRight {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    20% {
        opacity: 1;
        transform: translateX(0);
    }
    50% {
        opacity: 1;
        transform: translateX(0);
        color:var(--color3);
    }
    80% {
        opacity: 1;
        transform: translateX(20px);
        color:var(--color4);
    }
    100% {
        opacity: 1;
        transform: translateX(20px);
        color:var(--color4);
    }
}

/*Move CS left ************************************ */
.main-logo-text .main-logo-first {
    animation: fadeLeft 1s;
    animation-timing-function: 0.1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation: fadeLeft 1s;
    -webkit-animation-timing-function: 0.1s;
    -webkit-animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
}

@keyframes fadeLeft {
    0% {
        opacity: 1;
        transform: translateX(0);
    }
    20% {
        opacity: 1;
        transform: translateX(0);
    }
    50% {
        opacity: 1;
        transform: translateX(0);
    }
    80% {
        opacity: 1;
        transform: translateX(-30px);
    }
    100% {
        opacity: 1;
        transform: translateX(-30px);
    }
}

/* Amimate YE ************************************ */
.main-logo-text li.ghost-1 {
    position: relative;
    left: -30px;
    color:var(--color4);
}

.main-logo-text li.ghost-2 {
    position: relative;
    left: -27px;
    color:var(--color4);
}

.main-logo-text li.ghost-3 {
    position: relative;
    left: -22px
}

.main-logo-text .ghost-1 {
    opacity: 0;
    animation: fadeSimple 1.5s ease-in-out;
    animation-timing-function: 0.1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation: fadeSimple 1.5s ease-in-out;
    -webkit-animation-timing-function: 0.1s;
    -webkit-animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
}

.main-logo-text .ghost-2 {
    opacity: 0;
    animation: fadeSimple 1.7s ease-in-out;
    animation-timing-function: 0.1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
    animation-iteration-count: infinite;
    -webkit-animation-iteration-count: infinite;
    -webkit-animation: fadeSimple 1.7s ease-in-out;
    -webkit-animation-timing-function: 0.1s;
    -webkit-animation-delay: 1s;
    -webkit-animation-fill-mode: forwards;
}

@keyframes fadeSimple {
    0% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    40% {
        opacity: 0;
    }
    50% {
        opacity: 1;
    }
    80% {
        opacity: 1;
    }
    100% {
        opacity: 1;
    }
}


.main-logo-text .ghost-3 {
    animation: fadePct 1.2s ease-in-out;
    animation-timing-function: 0.1s;
    animation-delay: 1s;
    animation-fill-mode: forwards;
}

@keyframes fadePct {
    0% {
        opacity: 1;
    }
    20% {
        opacity: 1;
    }
    40% {
        opacity: 1;
    }
    50% {
        opacity: 0;
    }
    80% {
        opacity: 0;
    }
    100% {
        opacity: 0;
    }
}

/* Animate ! ************************************ */
.main-logo-text .ghost-sign {
    left: 92px;
    top: 4px;
    position: absolute;
    // color: rgb(243, 232, 80);
    color:var(--color4);
    opacity: 0;
    font-size: 33px;
    padding: 0 5px;
    animation: fadeSignAfter 1s forwards infinite alternate;
    animation-timing-function: 22s;
    animation-delay: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards alternate;
    -webkit-animation: fadeSignAfter 1s forwards infinite alternate;
    -webkit-animation-timing-function: 22s;
    -webkit-animation-delay: 2s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards alternate;
}

@keyframes fadeSign {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
    }
    20% {
        opacity: 1;
        transform: scale(1.5) rotate(10deg);
    }
    40% {
        opacity: 1;
        transform: scale(1.9) rotate(10deg);
    }
    60% {
        opacity: 1;
        transform: scale(1.9) rotate(10deg);
    }
    80% {
        opacity: 1;
        transform: scale(1.7) rotate(10deg);
    }
    100% {
        opacity: 1;
        transform: scale(1.5) rotate(10deg);
    }
}

@keyframes fadeSignAfter {
    0% {
        opacity: 1;
        transform: scale(1.5) rotate(10deg);
    }
    15% {
        opacity: 1;
        transform: scale(1.5) rotate(0deg);
    }
    20% {
        opacity: 1;
        transform: scale(1.9) rotate(10deg);
    }
    40% {
        opacity: 1;
        transform: scale(1.9) rotate(0deg);
    }
    60% {
        opacity: 1;
        transform: scale(1.9) rotate(10deg);
    }
    80% {
        opacity: 1;
        transform: scale(1.7) rotate(0deg);
    }
    100% {
        opacity: 1;
        transform: scale(1.5) rotate(10deg);
    }
     
}

/* Animate YES underline ************************************ */
.yes-line {
    opacity: 0;
    top: 47px;
    left: 24px;
    width: 70px;
    position: absolute;
    border: none;
    // border-bottom: 1px solid #f3e850;
    border-bottom: 1px solid var(--color4);;
    box-shadow: 2px 2px 3px rgba(0, 0, 0, 0.91);
    animation: fadeLine .2s;
    animation-timing-function: 0.3s;
    animation-delay: 2s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    -webkit-animation: fadeLine .2s;
    -webkit-animation-timing-function: 0.3s;
    -webkit-animation-delay: 2s;
    -webkit-animation-iteration-count: 1;
    -webkit-animation-fill-mode: forwards;
}

@keyframes fadeLine {
    0% {
        opacity: 0;
    }
    15% {
        opacity: 1;
        width: 0px;
    }
    20% {
        opacity: 1;
        width: 10px;
    }
    40% {
        opacity: 1;
        width: 30px;
    }
    60% {
        opacity: 1;
        width: 50px;
    }
    80% {
        opacity: 1;
        width: 70px;
    }
    100% {
        opacity: 1;
        width: 70px;
    }
}

/* 'Team' anim ************************************ */
.main-logo-team {
    color: white;
    text-decoration: none;
    text-align: center;
    /* font-weight: bold; */
    letter-spacing: 2.5px;
    opacity: 0;
    margin-top: 22px;
    animation: team 0.2s linear 3s forwards alternate;
    text-shadow: 2px 2px 3px rgba(0, 0, 0, 0.91);
        >span{
            font-size: 14px;
            color: var(--text3);
        }
}

@keyframes team {
    0% {
        opacity: 0;
        margin-right: 300px;
    }
    80% {
        opacity: 1;
        margin-right: -200px;
        /* transform: scale(1.9) */
    }
    100% {
        opacity: 1;
        margin-right: -120px;
        /* transform: scale(1) */
    }
}


/* Responsiveness */
@media only screen and (min-width: 2000px) {
    .main-logo-body {
        height: 140px;
    }
    .main-logo-team {
        margin-top: 30px;
    }
}

// @media only screen and (max-width: 799px) {
//     .main-logo-body {
//         height: 100px;
//     }
//     .main-logo-team {
//         margin-top: 10px;
//     }
// }